import { render, staticRenderFns } from "./paymentGateways.vue?vue&type=template&id=fffa31be&scoped=true&"
import script from "./paymentGateways.vue?vue&type=script&lang=js&"
export * from "./paymentGateways.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fffa31be",
  null
  
)

export default component.exports